import { Table } from "react-bootstrap";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const DataTableUserSession = (props) => {
  return (
    <>
      <Table
        className={"data-table  table-responsive-xl"}
        responsive={true}
        striped
        hover
      >
        <thead className={"txt-light"}>
          <tr>
            {props.headers.map((item, index) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody className={"bg-light txt-light rounded"}>
          {props.data.map((item, index) => (
            <tr key={index}>
              <td>{item.platform}</td>
              <td>{item.deviceModel}</td>
              <td>{item.IP}</td>
              <td>{item.createdAt.replace("+00:00", "").replace("T", " ")}</td>
              <td>{item.updatedAt.replace("+00:00", "").replace("T", " ")}</td>
              <td>
                {item.revokedAt !== null
                  ? item.revokedAt.replace("+00:00", "").replace("T", " ")
                  : item.revokedAt}
              </td>
              <td>
                {item.first_device ? (
                  <FontAwesomeIcon
                    FontAwesomeIcon
                    style={{
                      fontSize: "1.2em",
                      color: "green",
                    }}
                    icon={faCheck}
                  />
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default DataTableUserSession;
