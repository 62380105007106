import React, { Component } from 'react'
import { Spinner } from 'react-bootstrap'

export default class Loading extends Component {
    render() {
        return (
            <div
                className={'d-flex justify-content-center align-items-center'}
                style={{ height: '100vh' }}
            >
                <Spinner animation="grow" variant="light" />
            </div>
        )
    }
}
